<template>
  <div class="fluid" id="authorityList">
    <div>
      <div class="authority-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
            >
              <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
                HIRARKI WEWENANG
              </h6>
            </v-col>
            <v-col cols="12">
              <v-toolbar
                class="authority-list-toolbar-1"
                flat
                style="height: 60px; border-radius: 10px 10px 0 0"
              >
                <div style="width: 140px" class="me-5">
                  <v-select
                    :disabled="selectedData.length === 0"
                    label="Action"
                    style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                    v-model="actionValue"
                    :items="[
                      { id: 0, name: '' },
                      { id: 1, name: 'Edit' },
                      { id: 2, name: 'Delete' }
                    ]"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                    @change="action"
                  >
                  </v-select>
                </div>
                <v-dialog v-model="dialog" width="500" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold me-5"
                      style="font-size:12px;"
                      @click="addData"
                      :disabled="loading"
                    >
                      Tambah Data
                    </v-btn>
                  </template>

                  <v-form
                    :disabled="loading"
                    ref="entryForm"
                    @submit.prevent="submit"
                    style="position: relative;"
                  >
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Tambah Data
                      </v-card-title>

                      <v-card-text class="d-flex justify-center align-center">
                        <v-row
                          cols="12"
                          style="padding: 0 10px; position:relative; top:20px;"
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            style="padding: 0 5px; margin-bottom:10px;"
                          >
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Employee
                            </p>
                            <v-autocomplete
                              ref="autocomplete2"
                              v-model="form.employee"
                              :items="dropdown.employee"
                              :rules="employeeRules"
                              :search-input.sync="employeeSearch"
                              item-text="name"
                              item-value="nik"
                              return-object
                              outlined
                              hide-details
                              hide-no-data
                              clearable
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" style="padding: 0 5px;">
                            <p
                              class="text-left"
                              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Parent
                            </p>
                            <v-autocomplete
                              ref="autocomplete"
                              v-model="form.parent"
                              :items="dropdown.parent"
                              :search-input.sync="parentSearch"
                              item-text="employee.name"
                              item-value="id"
                              return-object
                              outlined
                              hide-details
                              hide-no-data
                              clearable
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="error"
                          text
                          outlined
                          @click="close"
                          :loading="loading"
                        >
                          Batal
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          type="submit"
                          text
                          outlined
                          :loading="loading"
                        >
                          Simpan
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
              </v-toolbar>
              <v-toolbar
                class="authority-list-toolbar-1"
                flat
                style="height: 60px; border-radius: 10px 10px 0 0"
              >
                <div style="width:150px;">
                  <v-select
                    @change="companyWatcher"
                    v-model="paramAPI.company_id"
                    :items="getDropdownPlant"
                    style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                    "
                    label="Company"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                    clearable
                  ></v-select>
                </div>
                <div style="width:150px;">
                  <v-select
                    @change="departmentWatcher"
                    v-model="paramAPI.department_id"
                    :items="dropdown.department"
                    style="
                      position: relative;
                      top: 15px;
                      font-size: 12px; margin-left: 15px;
                    "
                    label="Department"
                    item-text="name"
                    item-value="id"
                    outlined
                    return-id
                    dense
                    clearable
                  ></v-select>
                </div>
                <div style="width: 180px; margin-left: 15px;" class="me-5">
                  <v-text-field
                    v-model="paramAPI.keyword"
                    label="Cari disini"
                    type="search"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    style="position: relative; top: 15px;"
                    @keyup.enter="searchEnter"
                    :disabled="loading"
                  ></v-text-field>
                </div>
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="58vh"
                v-model="selectedData"
                :headers="headers"
                style="cursor: pointer;"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                show-select
                :single-select="true"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.employee`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.employee.name }}
                  </div>
                </template>
                <template v-slot:[`item.department`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.department.name }}
                  </div>
                </template>
                <template v-slot:[`item.level`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    {{ item.level.name }}
                  </div> </template
                ><template v-slot:[`item.parent`]="{ item }">
                  <div
                    style="font-size:12px; padding: 0; display: flex; flex-direction: row"
                  >
                    <div>
                      <v-chip small color="default" label class="m-1">
                        {{
                          item.parent !== null ? item.parent.employee.name : '-'
                        }}
                      </v-chip>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-dialog
      v-if="hierarchy !== null"
      v-model="hierarchyDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          HIRARKI
        </v-card-title>
        <v-card-text class="d-flex justify-center align-center">
          <v-treeview :items="hierarchy"></v-treeview>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            text
            outlined
            @click="hierarchyClose"
            :loading="loading"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
// import XLSX from 'xlsx'
export default {
  name: 'hierarchyposition',
  data: () => ({
    dialog: false,
    hierarchyDialog: false,
    hrsApi: buildType.apiURL('hrs'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      length: 0,
      // sortBy: 'employee',
      // sortType: 'asc',
      company_id: null,
      department_id: null,
      position_id: null,
      itemsPerPage: 10,
      year: '',
      employee_id: ''
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'Employee',
        value: 'employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: false
      },
      {
        text: 'Position',
        value: 'level',
        align: 'left',
        sortable: false
      },
      {
        text: 'Parent',
        value: 'parent',
        align: 'left',
        sortable: false
      }
    ],
    dropdown: {
      status: [
        {
          name: 'Active',
          id: 1
        },
        {
          name: 'Unactive',
          id: 2
        }
      ],
      department: [],
      position: [],
      company: [],
      parent: [],
      employee: []
    },
    parentSearch: null,
    employeeSearch: null,
    select: null,
    select2: null,
    result: [],
    selectedData: [],
    loading: false,
    form: {
      act: 'add',
      id: '',
      employee: null,
      parent: null
    },
    employeeRules: [],
    hierarchy: null
  }),

  async mounted() {
    // this.paramAPI.company_id = Number(
    //   this.getUserProfile.employee.company.plant_id
    // )
    await this.getDataFromApi()
    this.setAction()
    this.initDropdown()
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant'])
  },
  watch: {
    parentSearch(val) {
      if (val !== '') {
        val && val !== this.select && this.querySelections(val)
      } else {
        this.select = null
        this.dropdown.parent = []
      }
    },
    employeeSearch(val) {
      if (val !== '') {
        val && val !== this.select2 && this.querySelections2(val)
      } else {
        this.select2 = null
        this.dropdown.employee = []
      }
    }
  },
  methods: {
    ...mapActions(['dropdownPlant']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    async initDropdown() {
      await this.dropdownPlant()
      await this.dropdownPosition()
    },
    querySelections(v) {
      let url = `${this.hrsApi}employee_hierarchy/dropdown?keyword=${v}`
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      axios
        .get(url)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.dropdown.parent = res.data.data)
          }
          return (this.dropdown.parent = [])
        })
        .catch(err => {
          this.dropdown.parent = []
          return console.log(err)
        })
    },
    querySelections2(v) {
      axios
        .get(`${this.hrsApi}employee/list?keyword=${v}&offset=0&limit=10`)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            const arr = []
            for (let i = 0; i < res.data.data.length; i++) {
              arr.push({
                id: res.data.data[i].id,
                name: res.data.data[i].name,
                nik: res.data.data[i].nik
              })
            }
            console.log(arr)
            return (this.dropdown.employee = res.data.data)
          }
          return (this.dropdown.employee = [])
        })
        .catch(err => {
          this.dropdown.employee = []
          return console.log(err)
        })
    },
    close() {
      this.$refs.entryForm.reset()
      // this.$refs.autocomplete.cachedItems = []
      // this.$refs.autocomplete.computedItems = []
      this.form = {
        act: 'add',
        id: '',
        employee: null,
        parent: null
      }
      this.dropdown.employee = []
      this.dropdown.parent = []
      this.paramAPI.company_id = null
      this.paramAPI.department_id = null
      this.actionValue = 0
      this.selectedData = []
      this.dialog = false
    },
    action(event) {
      switch (event) {
        case 1:
          if (this.selectedData.length < 2) {
            this.edit()
          } else {
            this.showMsgDialog(
              'warning',
              'Maaf, untuk saat ini Anda hanya dapat mengedit 1 data pada satu waktu',
              false
            )
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = null
            }, 200)
          }
          break
        case 2:
          if (this.selectedData.length < 2) {
            this.delete()
          } else {
            this.showMsgDialog(
              'warning',
              'Maaf, untuk saat ini Anda hanya dapat menghapus 1 data pada satu waktu',
              false
            )
            setTimeout(() => {
              this.selectedData = []
              this.actionValue = null
            }, 200)
          }
          break
      }
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 2000)
      })
    },
    async initDataTable() {
      let url = `${this.hrsApi}employee_hierarchy/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }`
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id}`
      }
      return await new Promise(resolve => {
        axios
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    addData() {
      this.form.act = 'add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },

    submit() {
      this.empoyeeRules = [v => !!v || 'Employee is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        parent_id:
          this.form.parent !== null ? Number(this.form.parent.id) : null,
        employee_id: this.form.employee.id
      }
      if (this.form.act === 'update') {
        Object.assign(form, { id: this.form.id })
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee_hierarchy/save`, form)
        .then(async res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            await this.getDataFromApi()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    rowClick(pItem) {
      axios
        .get(
          `${this.hrsApi}employee_hierarchy/hierarchy?employee_id=${pItem.employee.id}`
        )
        .then(res => {
          this.hierarchy = [res.data.data]
          console.log(this.hierarchy)
          setTimeout(() => {
            this.hierarchyDialog = true
          }, 300)
        })
        .catch(err => {
          console.log(err)
        })
    },
    hierarchyClose() {
      this.hierarchyDialog = false
      this.hierarchy = null
    },
    async edit() {
      console.log(this.selectedData[0])
      this.querySelections(
        this.selectedData[0].parent !== null
          ? this.selectedData[0].parent.employee.name
          : ''
      )
      this.querySelections2(this.selectedData[0].employee.nik)

      this.form.act = 'update'
      this.form.id = this.selectedData[0].id
      this.form.parent = this.selectedData[0].parent
      this.form.employee = this.selectedData[0].employee
      console.log(this.form)
      setTimeout(() => {
        this.dialog = true
      }, 500)
    },
    async delete() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async res => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(
              `${this.hrsApi}employee_hierarchy/delete/${this.selectedData[0].id}`
            )
            .then(async res => {
              this.setAction()
              this.showMsgDialog('success', res.data.status_msg, 'false')
              await this.getDataFromApi()
            })
            .catch(err => {
              console.log(err)
            })
          this.loading = false
        } else {
          this.actionValue = null
        }
      })
    },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    async companyWatcher(p) {
      this.paramAPI.company_id = p

      await this.getDataFromApi()
      this.dropdownDepartment()
    },
    async departmentWatcher(p) {
      this.paramAPI.department_id = p

      await this.getDataFromApi()
    },
    async dropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.paramAPI.company_id}}]`
        )
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          this.dropdown.section = []
          return (this.dropdown.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async dropdownPosition() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.position = res.data.data)
          }
          return (this.dropdown.position = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.position = [])
        })
    },

    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async searchEnter() {
      await this.getDataFromApi()
    }
  }
}
</script>
<style lang="scss">
#authorityList {
  position: relative;
  .authority-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .authority-list-toolbar-1 {
      .authority-list-toolbar-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .authority-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #authorityList {
    .authority-list-div {
      .authority-list-toolbar-1 {
        .authority-list-toolbar-div {
          display: none;
        }
      }
      .authority-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
